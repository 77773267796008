
import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"
import queryString from "query-string"

import lecturesData from "./../data/lectures.yaml"

import "../components/css/page/univ-dc.css"

class UnivDiscount extends React.Component {

  state = {
    code: '',
    title: '',
    link: ''
  }

  render () {

    return (
      <Layout page="univ-dc">
        <SEO title="얄코강좌 대학생 할인" />
        <section id="univDc">
          <h1>
            얄코강좌 대학생 할인
            <span role="img" aria-label="books">🎓</span>
          </h1>
          <h2>
            { this.state.title }
            {
              this.state.link && (
                <a href={ this.state.link} target="_blank" rel="noreferrer">
                  강의 바로가기
                </a>
              )
            }
          </h2>
          <div>
            <div className="info">
              <strong>대학교 이메일 계정</strong>으로 쿠폰을 요청하시면 <strong>쿠폰 번호</strong>를 보내드립니다.
              {/* 단답형으로 쿠폰번호만 보내드리는 점 양해 부탁드립니다.
              <span role="img" aria-label="">😅</span> <br/> */}
              <p>
                <span role="img" aria-label="">⚠️</span>
                일부 대학의 메일계정이 스팸으로 분류되거나 답장이 전송실패 처리되고 있습니다.<br></br>하루 이상 답 메일이 없다면 다른 메일로, 학생증 또는 재학증명서(개인정보는 가릴 것)을 보내주시기 바랍니다.
                <p>
                  학교 메일계정으로의 답장이 (매 번 또는 자주) 전송실패 되는 대학교 : <br></br>
                  - 강원대, 단국대, 명지대, 서일대... ( 현재까지 확인된 바 )
                </p>
              </p>
            </div>
            <h3>
              <span role="img" aria-label="">🎫</span>
              반값쿠폰 받기
            </h3>
            <span className="note">
              * 반값 쿠폰은 다른 강의에서도 받을 수 있습니다.
            </span>
            <dl>
              <dt>받는이</dt>
              <dd>yalco@yalco.kr</dd>
              <dt>제목</dt>
              <dd>대학생 쿠폰 요청 - { this.state.code.toUpperCase() }</dd>
              <dt>내용</dt>
              <dd>대학교 이름 (이메일 계정 확인용)</dd>
            </dl>

          </div>
        </section>
      </Layout>
    )
  }

  componentDidMount () {
    const code = queryString.parse(window.location.search).lecture
    const lecture = lecturesData.filter((lec) => {
      return lec.code === code
    })[0]

    this.setState(() => {
      return {
        code: lecture.code,
        title: lecture.title,
        link: lecture.link
      }
    })
  }

}

export default UnivDiscount